.App {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
}

label {
  font-size: 1.3rem;
}

.loading {
  margin-top: 2rem;
}

.spinner-grow {
  margin-right: 1rem;
}

.row {
  margin-top: 2rem;
}

@media screen and (max-width: 700px) {
  .App {
    padding-top: 0;
  }
  .row {
    margin-top: 1.5rem;
  }
}
