.logo {
  height: 3rem;
  z-index: 1;
}
.powered {
  color: #3921ce;
  font-size: 0.8rem;
  float: right;
}
@media screen and (max-width: 700px) {
  .logo {
    height: 2.4rem;
  }
  .powered {
    font-size: 0.6rem;
  }
}
