.nps {
  font-size: 1.6rem;
  color: #cccccc;
  margin: 0.25rem;
}
.nps.on {
  color: #fec30a;
}
.button {
  border: none;
  background: transparent;
}
@media screen and (max-width: 700px) {
  .nps {
    margin: 0.25rem;
  }
  .button {
    padding-left: 0;
    padding-right: 1rem;
  }
}
